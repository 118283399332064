const header = document.querySelector('header')
const stageMediaSlide = document.querySelector('.stagemedia--slide')

function updateHeader() {
    let viewportTop = window.scrollY

    if (viewportTop > 20) {
        header.classList.remove('topStyle')
    }
    if (viewportTop <= 20) {
        header.classList.add('topStyle')
    }
}

if (!stageMediaSlide) {
    header.classList.remove('topStyle')
} else {
    window.addEventListener('scroll', updateHeader);
}