const body = document.querySelector('body')
const header = document.querySelector('header')
const toggleMenu = document.querySelector('.toggle--burger')
const navigationDefault = document.querySelector('.navigation--header')
const navigationSide = document.querySelector('.sideNavigation')
const navPoints = navigationDefault.querySelectorAll('a.navigation--listitem--link')
const subNavPoints = navigationDefault.querySelectorAll('a.subnavigation--listitem--link')
const sidePoints = navigationSide.querySelectorAll('a.sideNavigation--item--link')


// Close Navigation
const closeNavigation = () => {
    toggleMenu.classList.remove('active')
    navigationDefault.classList.remove('active')
    body.classList.remove('no-scroll')
}

// Navigation ActiveState
const getState = (item) => {
    const pathnames = location.pathname.split('/').slice(1)
    const pathKey = item.getAttribute('data-name')
    const isActive = pathnames.includes(pathKey)

    if(isActive) {
        item.parentElement.classList.add('active')
    }
}

navPoints.forEach(item => {
    getState(item);
    item.addEventListener('click', () => {
        closeNavigation()
    })
})
subNavPoints.forEach(item => {
    getState(item);
    item.addEventListener('click', () => {
        closeNavigation()
    })
})
sidePoints.forEach(item => {
    getState(item);
})

const adjustCurrent = () => {
    navigationDefault.querySelectorAll('.navigation--listitem--link').forEach(item => {
        getState(item)
    })
}

// Open/Close Navigation Mobile
toggleMenu.addEventListener('click', () => {
    toggleMenu.classList.toggle('active')
    navigationDefault.classList.toggle('active')
    body.classList.toggle('no-scroll')
    header.classList.toggle('topStyle')
})

